<template>
  <app-container
    v-if="DiyPost"
    :title="DiyPost.postHeading"
    :header="DiyPost.postHeading"
  >
    <app-snackbar v-model="showSnackbar" :text="snackbarText"></app-snackbar>
    <div class="diypost-container">
      <div class="what-to-do" v-if="DiyPost.whatToDo">
        <app-list-with-heading
          headerText="What to do:"
          :descriptions="DiyPost.whatToDo"
        >
        </app-list-with-heading>
      </div>
      <div
        class="what-to-do-container"
        v-html="DiyPost.whatToDoHtml"
        v-if="DiyPost.whatToDoHtml"
      ></div>
      <div class="diy-img-container">
        <app-img-download
          v-for="diyimg in DiyPost.images"
          :image="diyimg"
          :hideDownload="true"
          :key="diyimg"
        >
        </app-img-download>
      </div>
      <!-- +
            userDetail.donationLink 
            +'</p><p>#ChangeTheFuture</p>' 
            + userDetail.donationLink + '  #ChangeTheFuture'-->
      <div
        class="post-container"
        
        v-html="
          DiyPost.postHtml
        "
        v-if="DiyPost.postHtml"
      ></div>
      <div class="diy-text" v-if="DiyPost.postText">
        {{ DiyPost.postText }}
      </div>
      <div
        class="copy-container"
        @click="copyDonationLink"
        v-clipboard:copy="
          DiyPost.clipboardText 
        "
      >
        <v-btn class="copy-chip" color="#1A3263" small text>
          <span class="cpy-txt"> Copy Text</span>
          <font-awesome-icon icon="copy" />
        </v-btn>
      </div>
    </div>
    <app-snackbar
      :snackbar="showSnackbar"
      text="Copied successfully"
    ></app-snackbar>
    <app-dialog :dialog="showDialog"></app-dialog>
  </app-container>
</template>

<script>
import Container from "@/components/shared/Container.vue";
import Button from "@/components/shared/Button.vue";
import Snackbar from "@/components/shared/Snackbar.vue";
import Dialog from "@/components/shared/Dialog.vue";
import ImgDownload from "@/components/img-download/img-download.vue";

import { mapGetters, mapActions } from "vuex";
import ListWithHeading from "@/components/shared/ListWithHeading.vue";

export default {
  props: ["testProp", "passPostData"],
  watch: {
    testProp: function(newVal, oldVal) {
      this.postBtnClicked();
    },
    DiyPost: function(newVal, oldVal) {
      const btnText =
        this.DiyPost && this.DiyPost.completed
          ? "Mark as Not Posted"
          : "Mark as Posted";
      this.passPostData({ btnText, showPostBtn: true });
    },
  },
  methods: {
    ...mapActions({
      updateCompletedDiy: "user/CompletedDIYPost",
    }),
    copyDonationLink() {
      this.showSnackbar = true;
      this.snackbarText = "Copied Text";
    },
    async postBtnClicked() {
      try {
        const post = this.DiyPost;
        if (post.completed) {
          await this.updateCompletedDiy({
            postId: post.postId,
            action: "remove",
          });
        } else {
          await this.updateCompletedDiy({
            postId: post.postId,
          });
        }
        this.showSnackbar = true;
        this.snackbarText = "Updated successfully";
      } catch (err) {
        this.showSnackbar = true;
        this.snackbarText = "Unable to update the post.Please try again";
      }
    },
  },
  // old
  data: () => ({
    showSnackbar: false,
    showDialog: false,
    showSnackbar: false,
    snackbarText: "",
  }),
  computed: {
    ...mapGetters({
      getDIYPost: "diytask/getDIYPost",
      userDetail: "user/getUserDetail",
    }),
    DiyPost() {
      return this.getDIYPost(this.$route.params.diyid);
    },
  },
  components: {
    appContainer: Container,
    appButton: Button,
    appSnackbar: Snackbar,
    appDialog: Dialog,
    appListWithHeading: ListWithHeading,
    appImgDownload: ImgDownload,
  },
  mounted() {
    const btnText =
      this.DiyPost && this.DiyPost.completed
        ? "Mark as Not Posted"
        : "Mark as Posted";
    this.passPostData({ btnText, showPostBtn: true });
  },
};
</script>

<style scoped>
.diypost-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 2%;
}
.what-to-do-container {
  grid-column: 1/13;
}
.diy-text,
.diypost-container hr {
  grid-column: 1/13;
  text-indent: 20px;
  margin-bottom: 20px;
}
/* .postbtn1 {
    grid-row: 4;
    grid-column:6/11;
    justify-content: flex-end;
    display: flex;
  grid-column: 1/13;
  grid-row: 2/3;
  display: flex;
} */
.copy-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.copy-chip {
  background-color: #1a3263 !important;
  color: #e8e2db !important;
}
img {
  display: block;
  width: auto;
  max-width: 80%;
}
.what-to-do,
.copy-container {
  grid-column: 1/13;
}
.download-icon {
  grid-column: 11/13;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 33px;
  font-size: 21px;
}
.diy-img-container {
  grid-column: 1/13;
}
.cpy-txt {
  margin-right: 10px;
}
.post-container {
  grid-column: 1/13;
  margin-top: 20px;
}
</style>
