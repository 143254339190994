<template>
  <app-container title="Do It Yourself" header="Do It Yourself">
    <div>
      <app-snackbar v-model="showSnackbar" :text="snackbarText"></app-snackbar>
      <app-list-with-heading
        :headerText="whatToDo.headerText"
        :descriptions="whatToDo.descriptions"
      >
      </app-list-with-heading>
      <app-toggle-bar :chips="chips" v-model="selected"> </app-toggle-bar>
      <div class="post-card-container">
        <app-post-card
          v-for="task in getDiyArray(diyList)"
          :key="task.postId"
          :post="task"
          :postBtnClicked="postBtnClicked"
          @click.native="goToDiyPost(task.postId)"
        >
        </app-post-card>
      </div>
    </div>
  </app-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import Container from "@/components/shared/Container.vue";
import ListWithHeading from "@/components/shared/ListWithHeading.vue";
import PostCard from "@/components/postcard/PostCard.vue";
import ToggleBar from "@/components/toggleBar/togglebar.vue";
import Snackbar from "@/components/shared/Snackbar.vue";

export default {
  computed: {
    ...mapGetters({
      diyList: "diytask/getDIYList",
    }),
  },
  data: () => ({
    showSnackbar: false,
    snackbarText: "",
    showStatus: false,
    whatToDo: {
      headerText: "What to do?",
      descriptions: [
        "Do few small things to get attention for your fundraising on Social Media/Whatsapp status. You will find a list of interesting things to do here. Do It Yourself (DIY) Now.",
      ],
    },
    chips: ["Yet to post", "Posted"],
    selected: "Yet to post",
  }),
  components: {
    appContainer: Container,
    appListWithHeading: ListWithHeading,
    appPostCard: PostCard,
    appToggleBar: ToggleBar,
    appSnackbar: Snackbar,
  },
  methods: {
    ...mapActions({
      updateCompletedDiyPost: "user/CompletedDIYPost",
    }),
    goToDiyPost(id) {
      this.$router.push({
        name: "diypost",
        params: {
          diyid: id,
        },
      });
    },
    getDiyArray(DiyList) {
      if (this.selected === "Posted") {
        return DiyList.filter((diy) => diy.completed);
        //return DiyList;
      } else {
        return DiyList.filter((diy) => !diy.completed);
      }
      return DiyList;
    },
    async postBtnClicked(data, isCompleted) {
      try {
        if (isCompleted) {
          await this.updateCompletedDiyPost({
            postId: data,
            action: "remove",
          });
        } else {
          await this.updateCompletedDiyPost({
            postId: data,
          });
        }
        this.showSnackbar = true;
        this.snackbarText = "Updated successfully";
      } catch (err) {
        this.showSnackbar = true;
        this.snackbarText = "Unable to update the post.Please try again";
      }
    },
  },
};
</script>
<style scoped>
.status-switch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 35px;
}

.status-text {
  display: flex;
  margin: 0 12px;
  font-size: 17px;
}
.post-card-container {
  min-height: 300px;
}
</style>
