<template>
  <div class="copy-text-container">
    <div class="copy-text-title" v-html="howToDo.html"></div>

    <div class="copy-text-btn" @click="copyText"
        v-clipboard:copy="howToDo.copyText">
      <v-btn class="copy-chip" color="#1A3263" small text>
        <span class="cpy-txt"> Copy Text</span>
        <font-awesome-icon icon="copy" />
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: ["howToDo", "copyText"],
};
</script>
<style scoped>
.copy-text-container {
  display: grid;
  grid-template-columns: repeat(12,1fr);
  justify-content: center;
  align-items: center;
}
.copy-text-title, .copy-text-btn{
 grid-column: 1/13
}
.copy-text-btn {
  display:flex;
  justify-content: flex-end;
}
.copy-chip {
  background-color: #1a3263 !important;
  color: #e8e2db !important;
}
.cpy-txt {
  margin-right: 10px;
}
</style>
