import * as fb from '@/common/config';

export default {
    namespaced: true,
    getters: {
        getFaqList: state => {
            return state.faqList;
        },
        getScholarshipFaq: state => {
            return state.faqList.filter(faq => faq.type === 'Fight Against Covid').sort((a, b) => (a.orderId > b.orderId) ? 1 : -1);
        },
        getFundraisingfundFaq: state => {
            return state.faqList.filter(faq => (faq.type === 'Fundraising')).sort((a, b) => (a.orderId > b.orderId) ? 1 : -1);
        }

        // getFundraisingGenericFaq: state => {
        //     return state.faqList.filter(faq => (faq.type === 'Fundraising' && faq.fundraisingType === 'Generic')).sort((a, b) => (a.orderId > b.orderId) ? 1 : -1);
        // },
        // getFundraisingTransparencyFaq: state => {
        //     return state.faqList.filter(faq => (faq.type === 'Fundraising' && faq.fundraisingType === 'Transparency')).sort((a, b) => (a.orderId > b.orderId) ? 1 : -1);
        // },
        // getFundraisingfundFaq: state => {
        //     return state.faqList.filter(faq => (faq.type === 'Fundraising' && faq.fundraisingType === 'Fundraising Page')).sort((a, b) => (a.orderId > b.orderId) ? 1 : -1);
        // }
    },
    actions: {
        async FETCHFAQS({
            commit
        }) {


            const faqList = [];
            const snapshot = await fb.faqCollection.where("Status", "==", "Active").get()
            snapshot.docs.forEach(faq => {
                const faqDetails = faq.data()
                faqList.push(faqDetails)
            })
            commit('SET_FAQ_LIST', {
                faqList
            })
        }
    },
    mutations: {
        SET_FAQ_LIST(state, payload) {
            return state.faqList = payload.faqList;
        }
    },
    state: {
        faqList: []
    }
}