<template>
  <app-container title="Request Access" header="Request Access">
    <div class="access-container">
      <div class="text-center">
        <br />
        <!-- <p>
          Access Denied as the mobile number you used does not exist in our database.
        </p> -->

        <p>
          Click on the below 'Telegram' button and request access to the fundraising kit by sharing your Email Id and Mobile number.
        </p>
        <v-btn
          color="#1A3263"
          dark
          small
          elevation="2"
          tile
          :href="emailId"
          target="blank"
          >Telegram</v-btn
        >
        <br />
      </div>
      <br />
    </div>
  </app-container>
</template>
<script>
import Container from "@/components/shared/Container.vue";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      getEmailId: "user/getEmailId",
      getPhoneNumber: "user/getUserPhoneNumber"
    }),
    emailId(){
      //telegram
      return "https://telegram.me/@teameverestngo";
      //WhatsApp
      //return "http://api.whatsapp.com/send?phone=918939912365&text=Hi%20Team%20Everest,%20give%20me%20access%20for%20fundraising%20kit,%20My%20email%20id%20is%20"+this.getEmailId+(this.getPhoneNumber? "%20and%20my%20phone%20number%20is%20"+this.getPhoneNumber: "");
    }
  },
  components: {
    appContainer: Container,
  },
};
</script>
<style scoped>
.access-container {
  justify-content: center;
  align-items: center;
  padding: 2px;
}
</style>
