import * as fb from '@/common/config';

export default {
    namespaced: true,
    getters: {
        getFundraisingTipsList: state => {
            return state.fundraisingTips;
        },
        getFundraisingTips: state => {
            return state.fundraisingTips.filter(fundraisingTip => fundraisingTip.type === 'Tips').sort((a, b) => (a.orderId > b.orderId) ? 1 : -1);

        },
        getFundraisingMyths: state => {
            return state.fundraisingTips.filter(fundraisingTip => fundraisingTip.type === 'Myths').sort((a, b) => (a.orderId > b.orderId) ? 1 : -1);
        }
    },
    actions: {
        async FETCHFUNDRAISINGTIPS({
            commit
        }) {


            const fundraisingTipsList = [];
            const snapshot = await fb.fundraisingTipsCollection.where("tipStatus", "==", "Active").get()
            snapshot.docs.forEach(fundraisingTip => {
                const fundraisingTipDetails = fundraisingTip.data()
                fundraisingTipsList.push(fundraisingTipDetails)
            })
            commit('SET_FUNDRAISING_TIPS_LIST', {fundraisingTipsList})
        }
    },
    mutations: {
        SET_FUNDRAISING_TIPS_LIST(state, payload) {
            return state.fundraisingTips = payload.fundraisingTipsList;
        }
    },
    state: {
        fundraisingTips: []
    }
}