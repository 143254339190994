<template>
  <div v-on:scroll="handleScroll" class="app-container-full">
    <div class="fd-title page-title">{{ title }}</div>
    <div class="app-container" :class="classes">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    title: {
      type: String,
    },
    header: {
      type: String,
    },
    classes: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      showHeading: "getShowHeading",
    }),
  },
  methods: {
    ...mapActions({
      setHeadingStatus: "SETHEADERSTATUS",
      setHeadingText: "SETHEADERTEXT",
    }),
    onScroll1(ev, position) {},
    onScroll: function(e, position) {
      this.position = position;
    },
    handleScroll() {
      // Any code to be executed when the window is scrolled
      this.isUserScrolling = window.scrollY > 0;

      if (window.scrollY > 30) {
        if (!this.showHeading) {
          this.setHeadingStatus();
        }
      } else {
        if (this.showHeading) {
          this.setHeadingStatus();
        }
      }
    },
  },
  created() {
    this.setHeadingText("");
  },

  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    this.setHeadingText("");
    window.removeEventListener("scroll", this.handleDebouncedScroll);
  },
  mounted: function() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100);
    window.addEventListener("scroll", this.handleDebouncedScroll);
    this.setHeadingText(this.header);
  },
};
</script>

<style scoped>
.page-title {
  font-weight: bold;
  font-weight: 700;
  font-size: 22px;
  padding: 0 23px 30px 23px;
  /* background-color: white; */
  /* margin: 30px 0 15px 0; */
  color: darkslategrey;
}

.title {
  margin: 0;
  padding: 0;
}

.app-container {
  font-weight: bold;
  background-color: white;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding: 25px;
  flex: 1;
}
.app-container-full {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.nopadding {
  padding: 0px;
}
</style>
