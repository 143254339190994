import Vue from 'vue'
import App from './App.vue'
// import VueRouter from 'vue-router';
// import { routes } from './routes';
import './plugins/vuetify'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'


import {
  library
} from '@fortawesome/fontawesome-svg-core'
import {
  faUserSecret
} from '@fortawesome/free-solid-svg-icons'
import {
  faCoffee
} from '@fortawesome/free-solid-svg-icons'
import {
  faChevronRight,
  faQuestionCircle,
  faCopy
} from '@fortawesome/free-solid-svg-icons'
import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import {
  faCheck,
  faClone,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons'
import VueCountryCode from "vue-country-code";
import VueClipboard from 'vue-clipboard2';
import vuescroll from 'vue-scroll'
import store from './store';
import Vuetify from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { MobileValidate } from '@/utils/validates/MobileValidates'
import {
  install as VeeValidate,
  Validator
} from 'vee-validate'
import {
  required,
  min,
  max,
  email,
  length
} from 'vee-validate/dist/rules.esm'
import {
  auth
} from '@/common/config'

Validator.extend('required', required)
Validator.extend('max', max)
Validator.extend('min', min)
Validator.extend('email', email)
Validator.extend('length', length)
Validator.extend('phone', MobileValidate);
Vue.config.productionTip = false
Vue.use(VeeValidate)
Vue.use(VueClipboard);

library.add(faUserSecret)
library.add(faCoffee)
library.add(faChevronRight)
library.add(faCopy)
library.add(faQuestionCircle)
library.add(faCheck)
library.add(faClone)
library.add(faSignOutAlt)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.use(vuescroll)
Vue.use(VueCountryCode);
// Vue.use(VueRouter);

// const router = new VueRouter({
//   routes,
//   mode: 'history',
//   scrollBehavior(to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition;
//     }
//     if (to.hash) {
//       return { selector: to.hash };
//     }
//     return {x: 0, y: 0};
//   }
// });

// router.beforeEach((to, from, next) => {
//   next();
// });




let app
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      store,
      router,
      vuetify: new Vuetify({
        theme: {
          primary: '#ffe574',
          secondary: '#424242',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
        },
        icons: {
          iconfont: 'md'
        }
      }),
      render: h => h(App),
    }).$mount('#app')
  }
  if (user) {
    if (user.email && !store.getters['user/getLoginOrSignupInfo']) {
      store.dispatch('user/FetchUserDetail', {
        uid: user.uid,
        email: user.email
      }).then(res => {

      }).catch(err => {
        if (err === "document doesn't exist" && router.currentRoute.path !== '/accessrequest') {


          router.replace('/accessrequest')
        }

      })
    }
  }
})