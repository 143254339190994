<template>
  
    <app-container title="Your Buddy">
       <!-- Buddy details -->
       <div class="buddy-container">
          <!-- buddy image -->
       <div class="buddy-img-container">
<img
class="buddy-img"
:src="buddyDetails.buddyImage"
/>
</div>
       <!-- buddy name -->
        <div class="buddyname"> 
         <p> Your Everest Buddy is <strong>{{buddyDetails.buddyName}}</strong></p>
           </div>
           <!-- about buddy -->
           <div class="aboutbuddy">
              <p>{{buddyDetails.aboutBuddy}}</p>
           </div>
           <!-- Whatsapp buddy -->
           <div class="contact">
             <div> <a :href="buddyDetails.reachBuddy"
          ><img src="https://img.icons8.com/bubbles/100/000000/whatsapp.png"
        /></a>
        <p>Whats App {{ buddyDetails.buddyNickName }}</p></div>
        <!-- Call Buddy -->
        <div> <a :href="buddyDetails.callBuddy"
          ><img src="https://img.icons8.com/bubbles/100/000000/call-male.png"
        /></a>
        <p>Call {{ buddyDetails.buddyNickName }}</p>
        </div>
           </div>
          
       </div>
       <!-- end -->
    </app-container>
 
  

</template>
<script>
import { mapGetters } from "vuex";
import Container from '../../components/shared/Container.vue';
export default {
computed: {
...mapGetters({
userDetail: "user/getUserDetail",
buddyDetails: "user/getBuddyDetails"
}),
},
   components: {
    appContainer: Container
  }
}
</script>
<style scoped>
.buddy-img-container {
justify-content: center;
display: flex;
}
.buddy-img {
width: auto;
max-width: 60%;
border-radius: 50%;
}
.buddyname{
   padding-bottom: 2%;
  
}
.buddyname{
   padding: 10px;
   text-align: center;
}
.contact{
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-template-rows: 100px;
   grid-gap: 120px;
   text-align: center;

   
}

</style>