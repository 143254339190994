<template>
<app-container title="About Team Everest" header="About Team Everest">
   <div class="pagecontent">
      <h2 class="text-center" style="padding-bottom:3%; padding-top:3%;">About Team Everest</h2>

      <p class="text-left">Team Everest is a nonprofit organization that focuses on improving the quality of education and skills of underprivileged children in India. Our mission is to engage every Indian to volunteer at least once a month to help needy children.</p>

      <p class="text-left">Karthee Vidya, our Founder & CEO, started Team Everest in 2006. From his first-month salary, along with funds from 4 of his friends, he gathered Rs 4000. Karthee and his friends went to 'Sirumoor,' a small village in Tiruvannamalai district. They identified children and donated notebooks, uniforms, and dictionaries. It was the very first initiative of Team Everest. Later in 2014, Karthee quit his high-paying IT job for his passion, volunteering full-time through Team Everest.</p>
      <p class="text-left">What started as a small activity has taken wings over the last 14 years. Today, we are a family of 25000+ volunteers, 25 full-time employees, eight part-timers, and numerous interns touching the lives of 40000 children every year.</p>
      <p class="text-left">Team Everest is a registered trust in India with 80G certification. All donations made to Team Everest get a 50% tax exemption from the Government of India.</p>
      
      <p>Learn more about us at <a href="https://www.teameverest.ngo/" target="_blank">www.teameverest.ngo</a></p>
   <div class="text-center">
      <a href="https://firebasestorage.googleapis.com/v0/b/everestfundraise.appspot.com/o/CTF-2021%2FNewBrochure%2FTeam%20Everest%20Brochure.pdf?alt=media&token=b0bde99e-6b96-4e70-965d-44a33c46d302" target="_blank"> <v-btn color="#1A3263" dark tile>Download Brochure</v-btn></a>
    <!-- <a href="https://firebasestorage.googleapis.com/v0/b/everestfundraise.appspot.com/o/About%20Team%20Everest%2FTeam%20Everest%20Brochure.pdf?alt=media&token=e0bc7954-1d81-44ff-98b5-86e4b1b5df29" target="_blank"><button type="button" class="btn btn-lg button-inside">Download Brochure</button></a>  -->
   </div>
    </div>
</app-container>
</template>

<script>
import Container from '@/components/shared/Container.vue';
export default {
  components: {
        appContainer: Container,
    },
    
}
</script>

<style scoped>
.pagecontent
  {
    padding: 2%;
    font-weight: 400;
  }
.button-inside {
  background-color: #0392cf;
  color:#E8E2DB;
  /* color: white;   */
  }

</style>