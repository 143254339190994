import Vue from 'vue'
import VueRouter from 'vue-router'
// import Dashboard from '../views/Dashboard.vue'
import {
    auth
} from '@/common/config';
import store from '@/store';

import Header from '@/components/shared/Header1.vue';

import HomePage from '@/components/HomePage.vue'




import DIYList from '@/views/do-it-yourself/do-it-yourself-list/do-it-yourself-list.vue'
import DIYPost from '@/views/do-it-yourself/do-it-yourself-post/do-it-yourself-post.vue'



//Views
import Faq from '@/views/faq/faq.vue'
import FundraisingTips from '@/views/fundraisingtips/fundraisingtips.vue'
import Transparency from '@/views/transparency/transparency.vue'
import FightAgainstCovid from '@/views/fightagainstcovid/fightagainstcovid.vue'
import AboutTeamEverest from '@/views/aboutteameverest/aboutteameverest.vue'
import AccessRequest from '@/views/accessrequestpage/accessrequestpage.vue'
import YourBuddy from '@/views/yourbuddy/yourbuddy.vue'

//login
import Login from '@/views/login/login.vue'

//whatsapp ask
import WhatsappAsk from '@/views/whatsappask/whatsappask.vue'

//whatsapp
import WhatsappStatusList from '@/views/whatsappstatus/whatsappstatuslist/whatsappstatuslist.vue'
import WhatsappStatusPost from '@/views/whatsappstatus/whatsappstatuspost/whatsappstatuspost.vue'


Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/',
//     name: 'Dashboard',
//     component: Dashboard,
//     meta: {
//       requiresAuth: true
//     }
//   },
//   {
//     path: '/login',
//     name: 'Login',
//     component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
//   },
//   {
//     path: '/settings',
//     name: 'settings',
//     component: () => import( /* webpackChunkName: "settings" */ '../views/Settings.vue'),
//     meta: {
//       requiresAuth: true
//     }
//   }
// ]

const routes = [{
        path: '',
        name: 'home',
        components: {
            default: HomePage,
            'header-top': Header
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/whatsappask',
        name: 'whatsappask',
        components: {
            default: WhatsappAsk,
            'header-top': Header
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/whatsappstatus',
        name: 'whatsappstatus',
        components: {
            default: WhatsappStatusList,
            'header-top': Header
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/fightagainstcovid',
        name: 'fightagainstcovid',
        components: {
            default: FightAgainstCovid,
            'header-top': Header
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/transparency',
        name: 'transparency',
        components: {
            default: Transparency,
            'header-top': Header
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/aboutteameverest',
        name: 'aboutteameverest',
        components: {
            default: AboutTeamEverest,
            'header-top': Header
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/yourbuddy',
        name: 'yourbuddy',
        components: {
            default: YourBuddy,
            'header-top': Header
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/whatsappstatus/:whatsappstatusid',
        name: 'whatsappstatuspost',
        components: {
            default: WhatsappStatusPost,
            'header-top': Header
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/diylist',
        name: 'diylist',
        components: {
            default: DIYList,
            'header-top': Header
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/diylist/:diyid',
        name: 'diypost',
        components: {
            default: DIYPost,
            'header-top': Header
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/fundraisingtips',
        name: 'fundraisingtips',
        components: {
            default: FundraisingTips,
            'header-top': Header
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accessrequest',
        name: 'accessrequest',
        components: {
            default: AccessRequest
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/faq',
        name: 'faq',
        components: {
            default: Faq,
            'header-top': Header
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        components: {
            default: Login
        }
    },
    {
        path: '*',
        redirect: '/'
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        const fromHistory = Boolean(savedPosition);

        if (fromHistory && store.state.routerHistory.length > 0) {
            store.state.routerHistory.splice(-1, 1);
        } else {
            store.state.routerHistory.push(from);
        }

        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {
                selector: to.hash
            };
        }
        return {
            x: 0,
            y: 0
        };
    }
})

// navigation guard to check for logged in users
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    if (requiresAuth && (!auth.currentUser || !auth.currentUser.email)) {
        next('/login')
    } else {
        next()
    }
})

export default router