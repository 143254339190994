<template>
  <app-container
    title="Fundraising Tips"
    header="Fundraising Tips"
    classes="nopadding"
  >
    <app-toggle-bar :chips="chips" v-model="selected"> </app-toggle-bar>
    <hr />
    <div class="padding" :key="selected">
      <v-row justify="center">
        <v-expansion-panels inset>
          <v-expansion-panel
            v-for="fundraisingTip in fundraisingList"
            :key="fundraisingTip.tipId"
          >
            <v-expansion-panel-header>
              <span v-html="fundraisingTip.tipTitle"></span>
              </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span v-html="fundraisingTip.tipName"></span>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div>
  </app-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Container from "@/components/shared/Container.vue";
import ToggleBar from "@/components/toggleBar/togglebar.vue";

export default {
  computed: {
    ...mapGetters({
      getFundraisingTipsList: "fundraisingtips/getFundraisingTipsList",
    }),
    fundraisingList() {
      return this.selected === "Tips"
        ? this.$store.getters["fundraisingtips/getFundraisingTips"]
        : this.$store.getters["fundraisingtips/getFundraisingMyths"];
    },
  },
  created() {
    if (
      this.getFundraisingTipsList &&
      this.getFundraisingTipsList.length === 0
    ) {
      this.getFundraisingTipsDetails();
    }
  },
  data: () => ({
    selected: "Tips",
    chips: ["Tips", "Myths"],
  }),
  methods: {
    ...mapActions({
      getFundraisingTipsDetails: "fundraisingtips/FETCHFUNDRAISINGTIPS",
    }),
  },
  components: {
    appContainer: Container,
    appToggleBar: ToggleBar,
  },
};
</script>

<style scoped>
.v-expansion-panel {
  margin: 10px 0;
}
.padding {
  padding: 0 45px 45px;
}
.app-container hr {
  margin-top: 0px;
}
</style>
