<template>
  <v-overlay :value="isLoading">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      userLoader: "user/getLoaderInfo",
    }),
    isLoading() {
      return this.userLoader;
    },
  },
};
</script>

<style scoped>
.v-overlay {
  z-index: 2000 !important
}
</style>
