<template>
  <div>
    <v-btn
      v-if="type == 'link-btn'"
      color="primary"
      small
      elevation="2"
      tile
      :href="locationUrl"
      target="_blank"
    >
      {{ buttonText }}
    </v-btn>
    <v-btn v-else color="primary" small elevation="2" tile>{{
      buttonText
    }}</v-btn>
  </div>
</template>
<!--<a class="btn button-inside btn-lg float-right dwnldbtn" :href="statusDetails.thumbnailImage1" :download="statusDetails.thumbnailImage1" target="_blank">Download Status 1</a>-->
<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: "Button",
    },
    locationUrl: String,
    type: String,
  },
};
</script>

<style scoped>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #1a3263 !important;
  color: #e8e2db !important;
}
</style>
