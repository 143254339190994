<template>
  <app-container
    v-if="WhatsappStatusPost"
    :title="WhatsappStatusPost.postHeading"
    :header="WhatsappStatusPost.postHeading"
  >
    <div class="whatsappstatuspost-container">
      <div class="what-to-do">
        <app-snackbar
          v-model="showSnackbar"
          :text="snackbarText"
        ></app-snackbar>
        <app-list-with-heading
          headerText="What to do:"
          :descriptions="WhatsappStatusPost.whatToDo"
        >
        </app-list-with-heading>
      </div>
      <div class="whatsapp-img-container">
        <app-img-download
          v-for="whatsappimg in WhatsappStatusPost.images"
          :image="whatsappimg"
          :key="whatsappimg"
        >
        </app-img-download>
      </div>
      <!-- video -->
      <div class="whatsapp-video-container">
        <app-video-download
          v-for="whatsappvideo in WhatsappStatusPost.videos"
          :video="whatsappvideo"
          :key="whatsappvideo"
        >
        </app-video-download>
      </div>
    </div>
    <app-snackbar
      :snackbar="showSnackbar"
      text="Copied successfully"
    ></app-snackbar>
    <app-dialog :dialog="showDialog"></app-dialog>
  </app-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "@/router";

import Container from "@/components/shared/Container.vue";
import Button from "@/components/shared/Button.vue";
import Snackbar from "@/components/shared/Snackbar.vue";
import Dialog from "@/components/shared/Dialog.vue";
import ListWithHeading from "@/components/shared/ListWithHeading.vue";
import ImgDownload from "@/components/img-download/img-download.vue";
import VideoDownload from "@/components/video-download/video-download.vue";

export default {
  props: ["testProp", "passPostData"],
  watch: {
    testProp: function(newVal, oldVal) {
      this.postBtnClicked();
    },
    WhatsappStatusPost: function(newVal, oldVal) {
      const btnText =
        this.WhatsappStatusPost && this.WhatsappStatusPost.completed
          ? "Mark as Not Posted"
          : "Mark as Posted";
      this.passPostData({ btnText, showPostBtn: true });
    },
  },
  methods: {
    ...mapActions({
      updateCompletedWhatsapp: "user/CompletedWhatsappStatus",
    }),
    async postBtnClicked() {
      try {
        const post = this.WhatsappStatusPost;
        if (post.completed) {
          await this.updateCompletedWhatsapp({
            postId: post.postId,
            action: "remove",
          });
        } else {
          await this.updateCompletedWhatsapp({
            postId: post.postId,
          });
        }
        this.showSnackbar = true;
        this.snackbarText = "Updated successfully";
      } catch (err) {
        this.showSnackbar = true;
        this.snackbarText = "Unable to update the post.Please try again";
      }
    },
  },
  data: () => ({
    showSnackbar: false,
    showDialog: false,
    showSnackbar: false,
    snackbarText: "",
  }),
  computed: {
    ...mapGetters({
      getWhatsappStatusPost: "whatsappstatustask/getWhatsAppStatusPost",
    }),
    WhatsappStatusPost() {
      return this.getWhatsappStatusPost(this.$route.params.whatsappstatusid);
    },
  },
  components: {
    appContainer: Container,
    appButton: Button,
    appSnackbar: Snackbar,
    appDialog: Dialog,
    appListWithHeading: ListWithHeading,
    appImgDownload: ImgDownload,
    appVideoDownload: VideoDownload,
  },
  mounted() {
    const btnText =
      this.WhatsappStatusPost && this.WhatsappStatusPost.completed
        ? "Mark as Not Posted"
        : "Mark as Posted";
    this.passPostData({ btnText, showPostBtn: true });
  },
};
</script>

<style scoped>
.whatsappstatuspost-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 2%;
  
}

.what-to-do {
  grid-column: 1/13;
}

.whatsapp-img-container {
  grid-column: 1/13;
}
.whatsapp-video-container {
  grid-column: 1/13;
  
}
</style>
