// import * as firebase from 'firebase'

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'


// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyDAYHhYpCWFX9mksaKXmFV3IR6ekszpm48",
  authDomain: "covid-fundraise.firebaseapp.com",
 databaseURL: "https://covid-fundraise.firebaseio.com",
  projectId: "covid-fundraise",
  storageBucket: "covid-fundraise.appspot.com",
  messagingSenderId: "972069234425",
  appId: "1:972069234425:web:624aede00108ed5e83a428",
  measurementId: "G-PXPEM5ZEF9"
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore();

db.enablePersistence()
  .catch((err) => {
    console.error('Firebase error code is ', err);
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });
const auth = firebase.auth()

// collection references
const categoryMappingCollection = db.collection('categoryMapping2021')
const diyCollection = db.collection('diy2021')
const faqCollection = db.collection('faq')
const fundraisingTipsCollection = db.collection('fundraisingTips')
const whatsappstatusCollection = db.collection('whatsappstatus2021')
const buddyDetailsCollection = db.collection('buddyDetails2021')
const whatsappAskCollection = db.collection('whatappsAsk2021')

// export utils/refs
export {
  db,
  auth,
  categoryMappingCollection,
  diyCollection,
  faqCollection,
  fundraisingTipsCollection,
  whatsappstatusCollection,
  buddyDetailsCollection,
  whatsappAskCollection
}


// export const db = firebaseApp.firestore();
// export default db;