<template>
<app-container title="Fight Against Covid" header="Fight Against Covid">
   <div class="pagecontent text-left">
      <img src="https://firebasestorage.googleapis.com/v0/b/covid-fundraise.appspot.com/o/Fight%20Against%20Covid%2FIATC%20scholarship%20website%20banner.png?alt=media&token=047db551-724f-4ecb-be12-6a87ae0c935e">
      <br><br>
      <p>The virus is back. Not as a second wave, but almost like a Tsunami. </p>
      <p>Many families had lost their loved ones, who are the sole breadwinner of the family. Many families asked their cooks, house helps to stop coming home because of the fear of virus transmission creating job losses.</p>
      <p>While fighting for their loved ones in hospitals, many started to fight poverty in their homes.</p>
      <p>It's more than a year since their children stopped studying: no school, no online classes, and no tuition.</p>
      <p>Team Everest plans to help them with Groceries, Education kits, Livelihood relief, and Gadgets for Students.</p>
      <p>For more details, check <a href="https://www.teameverest.ngo/covid" target="_blank">www.teameverest.ngo/covid</a> </p>
      
      
      <br>
      <div class="text-center">
       
        <a href="https://firebasestorage.googleapis.com/v0/b/covid-fundraise.appspot.com/o/Fight%20Against%20Covid%2FFight%20Against%20Covid%20-%20Brochure%20New.pdf?alt=media&token=711124de-fb9a-4294-bb9e-0440cb761a65" target="_blank"> <v-btn color="#1A3263" dark tile>Download Brochure</v-btn></a>
         <!-- <a href="https://firebasestorage.googleapis.com/v0/b/everestfundraise.appspot.com/o/IATC%20Page%2FIATC%20-%20Brochure.pdf?alt=media&token=610082d7-a90c-4fc9-9280-04cb58722e9f" target="_blank"><button type="button" class="btn btn-lg button-inside">Download Brochure</button></a>  -->
      </div>
    </div>
</app-container>
</template>

<script>
import Container from '../../components/shared/Container.vue';
export default {
  components: {
        appContainer: Container,
    },
    
}
</script>

<style scoped>
img {
    max-width: 100%;
  }
  .pagecontent
  {
    padding: 2%;
    font-weight: 400;
  }
.button-inside {
  background-color: #0392cf;
  color:#E8E2DB;
  /* color: white; */
  }
  
</style>