<template>
  <div class="vid-container">
    <div class="download-vid">
      <video controls="controls" class="post-vid"  :src="video" type="video/mp4"/>
      
    </div>
    
    <div class="post-btn" v-if="!hideDownload">
      <app-button
        type="link-btn"
        :locationUrl="video"
        buttonText="download status"
      >
      </app-button>
      <div class="info-btn" @click="showInfo">
        <font-awesome-icon icon="question-circle" />
      </div>
      
    </div>
    
    <app-dialog
      v-model="showDialog"
      dialogTitle="How to download?"
      type="videodownloadinfo"
      dialogDescription="sdfs"
      dialogAgreeText="Dismiss"
      v-on:dialog-agreed="closeDialog"
    ></app-dialog>
  </div>
</template>

<script>
import Button from "@/components/shared/Button.vue";
import Dialog from "@/components/shared/Dialog.vue";

export default {
  data: () => ({
    showDialog: false,
  }),
  props: {
    video: {
      type: String,
    },
    hideDownload: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    appButton: Button,
    appDialog: Dialog,
  },
  methods: {
    showInfo() {
      this.showDialog = !this.showDialog;
    },
    closeDialog() {
      this.showDialog = !this.showDialog;
    },
  },
};
</script>

<style scoped>
.vid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.download-vid {
  grid-column: 1/13;
  display: flex;
  justify-content: center;
}

.post-vid {
  width: auto;
  width: 320px;
  height: auto;
  max-height: 240px;
}

.post-btn {
  grid-column: 1/13;
  justify-content: flex-end;
  display: flex;
  margin: 25px 0;
}
.info-btn {
  margin-left: 12px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  /* background-color: #ffe574 !important; */
  background-color: #fab95b !important;
  color: black;
}
</style>
