import * as fb from '@/common/config'
export default {
  namespaced: true,
  getters: {

    getDIYList: state => {
      return state.diyList;
    },

    getDIYPost: state => {
      return (id) => {
        return state.diyList.find(diy => diy.postId === id);
      }

    }
  },
  actions: {
    async FETCHDIYPOST({
      commit
    }, payload) {
      const diyList = [];
      // try{
      const snapshot = await fb.diyCollection.where("postStatus", "==", "active").get()

      snapshot.docs.forEach(status => {
        const diy = status.data()
        if (payload && payload.includes(diy.postId)) {
          diy.completed = true
        } else {
          diy.completed = false
        }
        diyList.push(diy)
      })
      commit('SET_DIY_LIST', diyList)
    },
      //update
      async UPDATEDIYLIST({
        commit,
        getters
      },payload) {
        const diyList = getters.getDIYList
        const getDiyArray = []
        diyList.forEach(diyPost => {
          if(payload && payload.includes(diyPost.postId)) {
            diyPost.completed= true
          }else {
            diyPost.completed= false
          }
          getDiyArray.push(diyPost)
        })
        commit('SET_DIY_LIST', getDiyArray)

    }
  },
  mutations: {
    SET_DIY_LIST(state, payload) {
      return state.diyList = payload;
    }
  },
  state: {
    diyList: []
  }
}