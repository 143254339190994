<template>
  <div>
    <div class="home-container" v-if="userDetail">
      <div class="title-bold">Hi {{ userDetail.name }},</div>
      <div class="welcome-text">
        Welcome to Team Everest fund raising kit.
      </div>
      <div class="fundraising-container">
        <div class="title-bold">Take Action</div>
        <div class="fundraising-icon-container">
          <div class="fundraising-icon" @click="goToPage('whatsappask')">
            <img
              class="action-img"
              src="https://img.icons8.com/clouds/50/000000/online-support.png"
            />
            <div>Whatsapp Ask</div>
          </div>
          <div class="fundraising-icon" @click="goToPage('whatsappstatus')">
            <img
              class="action-img"
              src="https://img.icons8.com/clouds/50/000000/mobile-shop-addres.png"
            />
            <div>Whatsapp Status</div>
          </div>
          <div class="fundraising-icon" @click="goToPage('diylist')">
            <img
              class="action-img"
              src="https://img.icons8.com/clouds/50/000000/hand-tools.png"
            />
            <div>Do It Yourself (DIY)</div>
          </div>
        </div>
      </div>
      <div class="donation-container" v-if="userDetail.donationLink">
        <div class="title-bold">Your donation link:</div>

        <div
          class="donoation-copy"
          v-clipboard:copy="userDetail.donationLink"
          @click="copyDonationLink"
        >
          <div class="donation-link">{{ userDetail.donationLink }}</div>
          <font-awesome-icon icon="clone" />
        </div>
      </div>
      <div>
        <div class="title-bold">Fundraising</div>
        <div class="faq-tips-container">
          <div class="faq-btn" @click="goToPage('fundraisingtips')">
            <div>
              <div class="icon-btn-container">
                <img
                  class="faq-icon-btn"
                  src="https://img.icons8.com/nolan/50/idea.png"
                />
              </div>
            </div>
            <div class="tips-faq-text">Fundraising Tips</div>
          </div>
          <div class="tips-btn" @click="goToPage('faq')">
            <div>
              <div class="icon-btn-container">
                <img
                  class="tips-icon-btn"
                  src="https://img.icons8.com/bubbles/50/000000/question-mark.png"
                />
              </div>
            </div >
            <div class="tips-faq-text">Fundraising FAQS</div>
          </div>
        </div>
      </div>
      <div class="buddy-container" v-if="buddyDetails">
        <div class="buddy-desc">
          <div class="contact-bold">Join this Telegram channel to get tips & suggestions to do Fundraising </div>
        </div>
        <div class="buddy-btn">
          <v-btn
            color="#1A3263"
            dark
            small
            elevation="2"
            tile
            :href="buddyDetails.reachBuddy"
            target="blank"
            >Telegram</v-btn>
        </div>        
      </div>
      <div class="about-container">
        <div class="title-bold">Others</div>
        <div class="about-icon-container">
          <div class="about-icon" @click="goToPage('fightagainstcovid')">
            <img
              src="@/assets/aboutlogo.png"
              
            />
            <div>Fight Against Covid</div>
          </div>
          <div class="about-icon" @click="goToPage('transparency')">
            <img
              src="https://img.icons8.com/ultraviolet/50/000000/person-in-a-mirror.png"
            />
            <div>Transparency</div>
          </div>
          <div class="about-icon" @click="goToPage('aboutteameverest')">
            <img src="@/assets/TELogo.png" />
            <div>Team Everest</div>
          </div>
        </div>
      </div>
      <app-snackbar v-model="showSnackbar" text="Link Copied"></app-snackbar>
    </div>
      </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/components/shared/Dialog.vue";
import Snackbar from "@/components/shared/Snackbar.vue";
import router from "@/router";
export default {
  computed: {
    ...mapGetters({
      userDetail: "user/getUserDetail",
      buddyDetails: "user/getBuddyDetails",
    }),
  },
  components: {
    appSnackbar: Snackbar,
  },
  data: () => ({
    showSnackbar: false,
  }),
  props: {
    buttonText: {
      type: String,
      default: "Button",
    },
  },
  methods: {
    copyDonationLink() {
      this.showSnackbar = !this.showSnackbar;
    },
    goToPage(name) {
      router.push({ name });
    },
  },
};
</script>

<style scoped>
.home-container {
  height: 100%;
  background-color: #fffffe;
  padding: 24px;
}
.welcome-text {
  text-indent: 30px;
}
.donation-container,
.fundraising-container,
.about-container,
.buddy-container,
.faq-tips-container {
  padding-top: 10px;
}
.title-bold {
  font-weight: 600;
  font-size: 18px;
}
.contact-bold {
    font-weight: 600;
  font-size: 12px;
  margin: 0 10px;
}
.faq-tips-container {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}
.faq-btn,
.tips-btn {
  /* width: 100px;
background-color: #ffe574 !important;
border-radius: 12px; */
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  width: 80px;
}
.faq-icon-btn,
.tips-icon-btn {
  background-color: white;
  border-radius: 50%;
  margin: 10px;
}
.tips-faq-text {
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  padding-bottom: 10px;
  width: 90%;
  margin: auto;
}
.donoation-copy {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 2px dashed slategray;
  border-radius: 10px;
  margin: 12px 0;
}
/* .buddy-name {
  font-size: 18px;
} */
.donation-link {
  margin-right: 15px;
  width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fundraising-icon-container,
.about-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 15px 0;
}
.fundraising-icon,
.about-icon {
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  width: 80px;
}
.buddy-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  justify-content: center;
  align-items: center;
  height: 85px;

  /* background-color: #ffe574 !important; */
  border-radius: 8px;
  margin: 10px 0;
  border: 1px solid #1a3263;
  padding: 0;
  /* border: 1px solid #2b3c4d; */
}
/* .buddy-img-container {
  grid-column: 1/5;
  justify-content: center;
  display: flex;
  grid-row: 1/3;
} */
/* .buddy-img {
  width: 100px;
  max-width: 100px;
  border-radius: auto;
  height: 100px;
  max-height: auto;
} */
.buddy-desc {
  grid-column: 1/13;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buddy-btn {
  grid-column: 1/13;
  display: flex;
  justify-content: center;
}
.fundraising-icon {
  width: 80px;
  /* background-color: #ffe574 !important; */
  background-color: #fab95b !important;
  border-radius: 15px;
  padding-bottom: 10px;
}
.fundraising-icon-container {
  background-color: white;
}

.fundraising-block {
  border: 1px solid lightgray;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  border-radius: 10px;
  grid-template-rows: 1fr 1fr;
  background-color: #cdd9cd !important;
  color: black;
  margin-bottom: 10px;
}
.new-fundrasing-container .title-bold {
  margin-bottom: 15px;
}
.fundrasing-text {
  grid-row: 2/3;
  grid-column: 1/13;
  font-size: 12px;
  font-weight: 600;
}
.fundraising-tile-icon {
  grid-column: 1/4;
}
.fundraising-tile-title {
  grid-column: 4/10;
  font-size: 16px;
  font-weight: 600;
  padding-top: 10px;
}
.fundraising-icon-style {
  background-color: white;
  border-radius: 50%;
}
.color1 {
  background-color: #ffdc91 !important;
}
.color2 {
  background-color: #add8ce !important;
}
.action-img {
  border-radius: 50%;
  background-color: white;
  margin: 10px 0;
}
</style>
