<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text v-if="dialogDescription">
          <!--<div v-if="type === 'logout'">{{ dialogDescription }}</div>-->
          <div v-if="type === 'downloadinfo'">
            <app-list-with-heading
              :descriptions="infoList"
            >
            </app-list-with-heading>
          </div>
           <div v-else-if="type === 'videodownloadinfo'">
            <app-list-with-heading
              :descriptions="videoInfoList"
            >
            </app-list-with-heading>
          </div>
          <div v-else>
          {{dialogDescription}}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            v-if="dialogDisagreeText"
            @click.stop="show = false"
          >
            {{ dialogDisagreeText }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click.stop="$emit('dialog-agreed')"
          >
            {{ dialogAgreeText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ListWithHeading from "@/components/shared/ListWithHeading.vue";
export default {
  props: {
    value: Boolean,
    dialogTitle: String,
    dialogDescription: String,
    dialogAgreeText: String,
    dialogDisagreeText: String,
    type: String,
    dialog: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
    timeout: {
      type: Number,
      default: 2000,
    },
  },
  data: () => ({
    infoList: [
      "Click the download button.",
      "It should open the new tab with the image to download",
      "Long press the image to get the toolbar",
      "Click 'download image' that should store the image in the device"
      ],
      videoInfoList: [
      "Click the download button.",
      "It should open the new tab with the video to download",
      "Click 'download video' that should store the video in the device"
      ]
  }),
  components: {
    appListWithHeading: ListWithHeading,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
