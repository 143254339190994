import * as fb from '@/common/config';

export default {
    namespaced: true,
    getters: {
        getWhatsppListAsk: state => {
            return state.whatsappAskList.filter(whatsappAsk => whatsappAsk.type === 'ask')[0];
        },
        getWhatsppListFollowUpDonate: state => {
            return state.whatsappAskList.filter(whatsappAsk => (whatsappAsk.type === 'follow up' && whatsappAsk.subType === 'yet to donate'))[0];
        },
        getWhatsppListFollowUpReply: state => {
            return state.whatsappAskList.filter(whatsappAsk => (whatsappAsk.type === 'follow up' && whatsappAsk.subType === 'yet to reply'))[0];
        },
        getWhatsppListThankyou: state => {
            return state.whatsappAskList.filter(whatsappAsk => (whatsappAsk.type === 'thank you'))[0];
        }
    },
    actions: {
        async FETCHWHATSAPPASKLIST({
            commit
        }) {


            const whatsappAskList = [];
            const snapshot = await fb.whatsappAskCollection.get()
            snapshot.docs.forEach(whatsappAsk => {
                const whatsappAskDetails = whatsappAsk.data()
                whatsappAskList.push(whatsappAskDetails)
            })
            commit('SET_WHATSAPP_ASK_LIST', {whatsappAskList})
        }
    },
    mutations: {
        SET_WHATSAPP_ASK_LIST(state, payload) {
            return state.whatsappAskList = payload.whatsappAskList  ;
        }
    },
    state: {
        whatsappAskList: []
    }
}