<template>
  <div class="chip-container">
    <div
      v-for="chip in chips"
      class="chip"
      :style="chipStyles"
      :class="{ selected: selected === chip }"
      @click.stop="selected = chip"
      :key="chip"
    >
      {{ chip }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chips: Array,
    value: String,
    chipStyles: Object
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.chip-container {
  display: flex;
  justify-content: center;
  padding: 40px 0px;
}
.chip {
  border: 1px solid dimgray;
  padding: 5px 30px;
  color: dimgray;
}
.chip:first-child,
.sub-chip:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.chip:last-child,
.sub-chip:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.selected {
  /* background-color: #2b3c4d; */
  background-color: #1A3263;
  color:#E8E2DB;
  /* color: white; */
}
</style>
