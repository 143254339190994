<template>
  <app-container
    title="Frequently Asked Questions"
    header="Frequently Asked Questions"
    classes="nopadding"
  >
    <app-toggle-bar :chips="chips" v-model="selected"> </app-toggle-bar>
    <hr class="selected-line" />
    <!-- <app-toggle-bar
      v-if="selected === 'Fundarising'"
      :chips="subChips"
      :chipStyles="chipStyles"
      v-model="selected"
    >
    </app-toggle-bar> -->
    <hr v-if="selected === 'Fundraising'" />
    <div class="padding" :key="selected">
      <v-row justify="center">
        <v-expansion-panels inset>
          <v-expansion-panel v-for="faq in faqList" :key="faq.faqId">
            <v-expansion-panel-header>
              <!-- {{faq.question }} -->
              <span v-html="faq.question"></span>
              </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span v-html="faq.answer"></span>
              <!-- {{ faq.answer }} -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div>
  </app-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Container from "@/components/shared/Container.vue";
import ToggleBar from "@/components/toggleBar/togglebar.vue";

export default {
  computed: {
    ...mapGetters({
      getFaqList: "faq/getFaqList",
    }),
    faqList() {
      if (this.selected === "Fight Against Covid") {
        return this.$store.getters["faq/getScholarshipFaq"];
      } else if (this.selected === "Fundraising") {
        return this.$store.getters["faq/getFundraisingfundFaq"];
        // if (this.subselected === "Generic") {
        //   return this.$store.getters["faq/getFundraisingGenericFaq"];
        // } else if (this.subselected === "Transparency") {
        //   return this.$store.getters["faq/getFundraisingTransparencyFaq"];
        // } else if (this.subselected === "Fundraising") {
        //   return this.$store.getters["faq/getFundraisingfundFaq"];
        // }
      }
      return [];
    },
  },
  created() {
    if (this.getFaqList && this.getFaqList.length === 0) {
      this.getFaqDetails();
    }
  },
  data: () => ({
    chips: ["Fight Against Covid", "Fundraising"],
    //subChips: ["Generic", "Transparency", "Fundraising"],
    selected: "Fight Against Covid",
    //subselected: "Generic",
    // chipStyles: {
    //   padding: "3px 8px",
    //   "min-width": "100px",
    //   "text-align": "center",
    // },
  }),
  methods: {
    ...mapActions({
      getFaqDetails: "faq/FETCHFAQS",
    }),
    select(value) {
      this.selected = value;
    },
    // subSelect(value) {
    //   this.subselected = value;
    // },
  },
  components: {
    appContainer: Container,
    appToggleBar: ToggleBar,
  },
};
</script>

<style scoped>
.v-expansion-panel {
  margin: 10px 0;
}

.padding {
  padding: 0 45px 45px;
}
.app-container hr {
  margin-top: 0px;
}
.app-container .selected-line {
  margin-bottom: 0px;
}
</style>
