<template>
  <app-container title="Transparency" header="Transparency">
    <div class="pagecontent text-left">
        <h2 style="padding-top: 2%; padding-bottom: 3%;"><strong>Commitment from<br>Team Everest</strong></h2>
      <p>Check out the below commitment from Team Everest. We love being transparent and build a community of volunteers who stand up for a cause.</p>
      <p><strong>1. Open and transparent Fundraising:</strong> Anyone who visits your fundraising page will know how much you have raised.</p>
      <p><strong>2. Know your donors:</strong> You can know who donated to your fundraising campaign by logging into your personalised fundraising page and going to 'Invite via Email' option. Your mobile number is your Login ID and you will get a OTP to login.</p>
      <p><strong>3. Social Media:</strong>We post about our distribution events and stories in all our social media pages (<a href="https://www.facebook.com/TeamEverestNGO/" target="_blank">Facebook</a>, <a href="https://www.instagram.com/teameverestngo/" target="_blank">Instagram</a>, <a href="https://www.linkedin.com/company/teameverestngo" target="_blank" >Linkedin</a>, <a href="https://twitter.com/teameverestngo" target="_blank">Twitter</a>). Follow us to know how your fundraising is making a difference.</p>
      <p><strong>4. You Tube Channel:</strong> We want all our donors to know how the beneficiaries are benefitted. Follow our<a href="https://www.youtube.com/c/TeamEverestNGO" target="_blank"> youtube channel</a> to know what your donations mean to them.</p>
      <p><strong>5. Volunteer:</strong> Go beyond fundraising. No matter where you are. You can volunteer to train our students virtually on Spoken English and many more initiatives. Check <a href="https://www.teameverest.ngo/childrenvolunteering" target="_blank">here</a> to know all virtual volunteering opportunities with Team Everest.</p><br>
      <!-- <p>You can know more about Team Everest transparency efforts in <a href="https://www.teameverest.ngo/transparency" target="_blank">www.teameverest.ngo/transparency</a></p> -->
      
    </div>
  </app-container>
</template>

<script>
import Container from '@/components/shared/Container.vue';
export default {
  components: {
        appContainer: Container,
    },
    
}
</script>

<style scoped>
 h2 {
    text-align: center;
  }

.pagecontent
  {
    padding: 2%;
    font-weight: 400;
  }
 

</style>