<template>
  <div class="post-container">
    <div class="post-title">{{ post.postHeading }}</div>
    <div class="arrow-icon">
      <font-awesome-icon icon="chevron-right" />
    </div>
    <div class="post-btn" v-if="post.completed">
      <font-awesome-icon icon="check" /> Posted
    </div>
    <div
      class="status-button"
      @click.stop="postBtnClicked(post.postId, post.completed)"
    >
      <app-button :buttonText="buttonText"></app-button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/shared/Button.vue";
export default {
  props: ["post", "postBtnClicked"],
  components: {
    appButton: Button,
  },
  computed: {
    buttonText() {
      return this.post && this.post.completed
        ? "Mark as Not Posted"
        : "Mark as Posted";
    },
  },
};
</script>

<style scoped>
.post-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  border: 1px dotted lightgray;
  border-radius: 10px;
  padding: 16px;
  -webkit-box-shadow: 5px 5px 10px #b4b4b4;
  -moz-box-shadow: 5px 5px 10px #b4b4b4;
  box-shadow: 5px 5px 10px #b4b4b4;
  /* border-left: 6px solid #ffe574; */
  border-left: 6px solid #FAB95B;
  margin: 20px 0;
  grid-gap: 10px;
}

.post-title {
  grid-column: 1/10;
}

.post-content {
  grid-column: 1/10;
  grid-row: 2/4;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 29px;
  color: dimgray;
}

.arrow-icon {
  grid-column: 11/13;
  grid-row: 1/2;
  display: grid;
  justify-content: center;
  align-items: center;
}

.status-button {
  grid-column: 6/13;
  display: flex;
  justify-content: flex-end;
}

.post-btn {
  grid-column: 1/6;
  border: 1px dashed lightgray;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: dimgray;
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  /* background-color: #2b3c4d !important; */
  background-color: #1A3263 !important;
  color:#E8E2DB;
  /* color: white; */
}

.v-application .primary--text {
  /* color: #ffe574 !important; */
  color: #FAB95B !important;
}
</style>
