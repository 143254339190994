<template>
  <div class="chip-container">
    <div
      class="chip"
      :style="chipStyles"
      :class="{ selected: selected === chip }"
      @click.stop="selected = chip"
      :key="chip"
      v-for="chip in chips"
    >
      {{ chip }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chips: Array,
    value: String,
    chipStyles: Object,
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.chip-container {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
}
.chip {
  padding: 2px 10px;
  border: 1px solid dimgray;
  margin-right: 20px;
  font-size: 14px;
}
.selected {
  /* background-color: #2b3c4d; */
  background-color: #1A3263;
  color:#E8E2DB;
  /* color: white; */
}
</style>
