<template>
  <div>
    <div class="intro-component">
      <div class="intro-text">{{ headerText }}</div>
      <ul>
        <li class="font-change" v-for="(desc, index) in descriptions" :key="index">
          {{ desc }}
        </li>
      </ul>
    </div>
    <hr v-if="!hideLine" />
  </div>
</template>

<script>
export default {
  props: {
    headerText: {
      type: String,
    },
    descriptions: {
      type: Array,
    },
    hideLine: {
      type: Boolean,
      default: false,
    },
    listWithoutDot: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.intro-component {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.intro-text {
  font-size: 21px;
}
.font-change {
  font-weight: 400;
}
</style>
