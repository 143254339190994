const MOBILEREG = /^[0-9]{8,10}$/;

export const MobileValidate = {
    
    getMessage(field, args) {
        return 'The phone number field must be a valid phone number';
    },
    validate(value, args) {
        return MOBILEREG.test(value);
    }
};